import React, { useContext } from 'react';
import * as styles from './CaseStudy.module.scss';
import { CreativeTypes } from '../../../hooks/useCreativeTypes';
import { GatsbyImage, IGatsbyImageData, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { Button } from '../../generic/Button';
import ArrowsSvg from '../../../assets/arrows-out.svg';
import { ModalContext } from '../../generic/Layout';
import { Carousel } from '../carousel/Carousel';
import { SourceProps } from 'gatsby-plugin-image/dist/src/components/picture';

export interface ICaseStudyProps {
  serviceLine: CreativeTypes;
  results?: string[];
  title: string;
  description: string;
  strategy?: string[];
  objective?: string;
  previewImage: IGatsbyImageData;
  modalImages: IGatsbyImageData[][];
}

export const CaseStudy = ({ serviceLine, results, title, description, strategy, objective, previewImage, modalImages }: ICaseStudyProps): JSX.Element => {
  const setModalState = useContext(ModalContext);
  
  const getResults = (): JSX.Element | null => {
    if (!results || results.length === 0) {
      return null;
    }

    const getResults = (): JSX.Element[] => {
      return results.map((result: string, index: number): JSX.Element => {
        return <li key={index}>{result}</li>;
      });
    };

    return <ul className={styles.CaseStudy__results}>{getResults()}</ul>;
  };

  const getStrategy = (): JSX.Element | null => {
    if (!strategy || strategy.length === 0) {
      return null;
    }

    const getStrategyItems = (): JSX.Element[] => {
      return strategy.map((entry: string, index: number): JSX.Element => {
        return <li key={index}>{entry}</li>;
      });
    };

    return <>
      <h4>Design Strategy</h4>
      <ul className={styles.CaseStudy__strategy}>{getStrategyItems()}</ul>
    </>;
  };

  const getObjective = (): JSX.Element | null => {
    if (!objective) {
      return null;
    }

    return <>
      <h4>Objective</h4>
      <p>{objective}</p>
    </>;
  };

  const openModal = (): void => {
    let images: JSX.Element[][] = [];
    
    for (const imageSet of modalImages) {
      images.push(imageSet.map((data: IGatsbyImageData, index: number): JSX.Element => {
        let image: JSX.Element;
        
        const imageSource: ImageDataLike | undefined = getImage(data);
        if (imageSource) {
          image = <GatsbyImage key={index} image={getImage(data) as IGatsbyImageData} alt={''}/>;
        } else {
          const source: SourceProps | undefined = data.images.sources?.[0];
          console.log(source);
          if (source) {
            image = <img key={index} {...source}/>;
          } else {
            image = <div>Could not find image</div>;
          }
        }

        return image;
      }));
    }

    images = images.filter((set: JSX.Element[]): boolean => set.length !== 0);

    setModalState({
      content: <Carousel images={images}/>
    });
  };

  const imageHoverText: string = serviceLine === 'CRO Experimentation' ? 'See Comparison' : 'View Larger';

  return (
    <article data-testid="CaseStudy" className={styles.CaseStudy} data-service-line={serviceLine}>
      <div className={styles.CaseStudy__content}>
        {getResults()}
        <h3>{title}</h3>
        <p>{description}</p>
        {getStrategy()}
        {getObjective()}
      </div>
      <Button onClick={openModal}>
        <div className={styles.CaseStudy__image}>
          <GatsbyImage image={previewImage} alt={title}/>
          <div className={styles.CaseStudy__imageExpand}>
            <ArrowsSvg/>
          </div>
          <div className={styles.CaseStudy__imageHover}>
            <div>{imageHoverText}</div>
          </div>
        </div>
      </Button>
    </article>
  );
};