import React from 'react';
import * as styles from './ServiceLineDisplay.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { IServiceLineProps, ServiceLine } from './service-lines/ServiceLine';
import { CreativeTypes } from '../../hooks/useCreativeTypes';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface ICaseStudy {
  description: string;
  modalImages: IGatsbyImageData[][];
  order: number;
  previewImage: IGatsbyImageData;
  title: string;
}

interface IGatsbyImageDataNode {
  gatsbyImageData: IGatsbyImageData;
}

interface ISharedNodeFields {
  description: {
    description: string;
  }
  previewImage: IGatsbyImageDataNode;
}

// CRO

interface ICroCaseStudy extends ICaseStudy {
  serviceLine: CreativeTypes.CRO;
  strategy: string[];
  results: string[];
}

interface ICroCaseStudyNode {
  node: ICroCaseStudy & ISharedNodeFields & {
    designStrategy: string[];
    modalImage1: IGatsbyImageDataNode[];
    modalImage2: IGatsbyImageDataNode[];
    modalImage3: IGatsbyImageDataNode[];
    modalImage4: IGatsbyImageDataNode[];
  }
}

// Performance Marketing

interface IPerformanceMarketingCaseStudy extends ICaseStudy {
  serviceLine: CreativeTypes.PerformanceMarketing;
}

interface IPerformanceMarketingCaseStudyNode {
  node: IPerformanceMarketingCaseStudy & ISharedNodeFields & {
    modalImage1: IGatsbyImageDataNode[];
    modalImage2: IGatsbyImageDataNode[];
    modalImage3: IGatsbyImageDataNode[];
  }
}

// Lifecycle Marketing

interface ILifecycleMarketingCaseStudy extends ICaseStudy {
  serviceLine: CreativeTypes.LifecycleMarketing;
  objective: string;
  results: string[];
}

interface ILifecycleMarketingCaseStudyNode {
  node: ILifecycleMarketingCaseStudy & ISharedNodeFields & {
    designStrategy: string[];
    objective: {
      objective: string;
    }
    modalImage1: IGatsbyImageDataNode[];
    modalImage2: IGatsbyImageDataNode[];
    modalImage3: IGatsbyImageDataNode[];
  }
}

// Landing Pages

interface ILandingPageCaseStudy extends ICaseStudy {
  serviceLine: CreativeTypes.LandingPages;
}

interface ILandingPageCaseStudyNode {
  node: ILandingPageCaseStudy & ISharedNodeFields & {
    modalImage1: IGatsbyImageDataNode[];
    modalImage2: IGatsbyImageDataNode[];
  }
}

interface ICroQuery {
  allContentfulCroExperimentationCaseStudy: {
    edges: ICroCaseStudyNode[];
  }
  allContentfulPerformanceMarketingCaseStudy: {
    edges: IPerformanceMarketingCaseStudyNode[];
  }
  allContentfulLifecycleMarketingCaseStudy: {
    edges: ILifecycleMarketingCaseStudyNode[];
  }
  allContentfulLandingPageCaseStudy: {
    edges: ILandingPageCaseStudyNode[];
  }
}

export const ServiceLineDisplay = (): JSX.Element => {
  const queryData = useStaticQuery<ICroQuery>(caseStudyQuery);
  const croNodes: ICroCaseStudyNode[] = queryData.allContentfulCroExperimentationCaseStudy.edges;
  const performanceNodes: IPerformanceMarketingCaseStudyNode[] = queryData.allContentfulPerformanceMarketingCaseStudy.edges;
  const lifecycleNodes: ILifecycleMarketingCaseStudyNode[] = queryData.allContentfulLifecycleMarketingCaseStudy.edges;
  const landingPageNodes: ILandingPageCaseStudyNode[] = queryData.allContentfulLandingPageCaseStudy.edges;

  const genericFilter = (studies: (ICaseStudy | null)[]): ICaseStudy[] => {
    return (studies.filter((study: ICaseStudy | null): boolean => !!study) as ICaseStudy[])
      .sort((a: ICaseStudy, z: ICaseStudy): number => a.order - z.order);
  };

  const croCaseStudies: ICroCaseStudy[] = genericFilter(croNodes.map((study: ICroCaseStudyNode): ICroCaseStudy | null => {
    const { description, designStrategy, previewImage, modalImage1, modalImage2, modalImage3, modalImage4 } = study.node;

    if (previewImage === null) {
      return null;
    }
    
    return {
      ...study.node,
      serviceLine: CreativeTypes.CRO,
      description: description.description,
      strategy: designStrategy,
      previewImage: previewImage.gatsbyImageData,
      modalImages: [
        (modalImage1 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData),
        (modalImage2 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData),
        (modalImage3 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData),
        (modalImage4 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData)
      ]
    };
  })) as ICroCaseStudy[];

  const performanceCaseStudies: IPerformanceMarketingCaseStudy[] = genericFilter(performanceNodes.map((study: IPerformanceMarketingCaseStudyNode): IPerformanceMarketingCaseStudy | null => {
    const { description, previewImage, modalImage1, modalImage2, modalImage3 } = study.node;

    if (previewImage === null) {
      return null;
    }
    
    return {
      ...study.node,
      serviceLine: CreativeTypes.PerformanceMarketing,
      description: description.description,
      previewImage: previewImage.gatsbyImageData,
      modalImages: [
        (modalImage1 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData),
        (modalImage2 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData),
        (modalImage3 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData)
      ]
    };
  })) as IPerformanceMarketingCaseStudy[];

  const lifecycleCaseStudies: ILifecycleMarketingCaseStudy[] = genericFilter(lifecycleNodes.map((study: ILifecycleMarketingCaseStudyNode): ILifecycleMarketingCaseStudy | null => {
    const { description, objective, previewImage, modalImage1, modalImage2, modalImage3 } = study.node;

    if (previewImage === null) {
      return null;
    }
    
    return {
      ...study.node,
      serviceLine: CreativeTypes.LifecycleMarketing,
      description: description.description,
      objective: objective.objective,
      previewImage: previewImage.gatsbyImageData,
      modalImages: [
        (modalImage1 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData),
        (modalImage2 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData),
        (modalImage3 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData)
      ]
    };
  })) as ILifecycleMarketingCaseStudy[];

  const landingPageCaseStudies: ILandingPageCaseStudy[] = genericFilter(landingPageNodes.map((study: ILandingPageCaseStudyNode): ILandingPageCaseStudy | null => {
    const { description, previewImage, modalImage1, modalImage2 } = study.node;

    if (previewImage === null) {
      return null;
    }
    
    return {
      ...study.node,
      serviceLine: CreativeTypes.LandingPages,
      description: description.description,
      previewImage: previewImage.gatsbyImageData,
      modalImages: [
        (modalImage1 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData),
        (modalImage2 ?? []).map((image: IGatsbyImageDataNode): IGatsbyImageData => image.gatsbyImageData)
      ]
    };
  })) as ILandingPageCaseStudy[];

  const serviceLines: IServiceLineProps[] = [
    {
      name: CreativeTypes.CRO,
      details: 'Don\'t let a lack of creative resources slow down your testing program. Our designers fit seamlessly into your CRO program to design test variations that look and feel like they were done in-house.',
      entries: croCaseStudies
    },
    {
      name: CreativeTypes.PerformanceMarketing,
      details: 'Social media and display ads are an integral touchpoint on the path to conversion. Captivate and convert audiences with data-informed, human-designed creative.',
      entries: performanceCaseStudies
    },
    {
      name: CreativeTypes.LifecycleMarketing,
      details: 'Convert leads, and build trust and loyalty with your brand. We design engaging, cohesive and impactful email campaigns that drive results.',
      entries: lifecycleCaseStudies
    },
    {
      name: CreativeTypes.LandingPages,
      details: 'Support acquisition efforts with dedicated, highly-performant landing pages, designed with content that drives users to take the next step.',
      entries: landingPageCaseStudies
    },
  ];

  const getServiceLines = (): JSX.Element[] => {
    return serviceLines.map((props: IServiceLineProps, index: number): JSX.Element => {
      return <ServiceLine key={index} {...props}/>;
    });
  };

  return <div data-testid="ServiceLineDisplay" className={styles.ServiceLineDisplay}>{getServiceLines()}</div>;
};

const caseStudyQuery = graphql`
  query CaseStudyQuery {
    allContentfulCroExperimentationCaseStudy {
      edges {
        node {
          description {
            description
          }
          designStrategy
          modalImage1 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          modalImage2 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          modalImage3 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          modalImage4 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          order
          previewImage {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          results
          title
        }
      }
    }
    allContentfulPerformanceMarketingCaseStudy {
      edges {
        node {
          description {
            description
          }
          modalImage1 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          modalImage2 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          modalImage3 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          order
          previewImage {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          title
        }
      }
    }
    allContentfulLifecycleMarketingCaseStudy {
      edges {
        node {
          description {
            description
          }
          modalImage1 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          modalImage2 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          modalImage3 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          objective {
            objective
          }
          order
          previewImage {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          results
          title
        }
      }
    }
    allContentfulLandingPageCaseStudy {
      edges {
        node {
          description {
            description
          }
          modalImage1 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          modalImage2 {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          order
          previewImage {
            gatsbyImageData(
              quality: 100,
              formats: [AUTO, WEBP]
            )
          }
          title
        }
      }
    }
  }
`;