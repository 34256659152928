export const random = (min: number, max: number): number => {
  if (min > max) {
    throw new Error('Min cannot be greater than max');
  }

  min = max ? Math.ceil(min) : 0;
  max = Math.floor(max ? max : min);
  return Math.floor(Math.random() * (max - min) + min);
};

/**
 * 
 * @param text 
 * @param count 
 * @returns 
 */
export const plural = (text: string, count: number): string => {
  return count === 1 ? text : `${text}s`;
};

/**
 * 
 * @param dateString 
 * @returns 
 */
export const formatDate = (dateString: string): string => {
  if (dateString === '') {
    return dateString;
  }

  return new Date(dateString).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'numeric'
  });
};

export const dateToNumber = (date: string): number => new Date(date).getTime();

// From: https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
export const slugify = (string: string): string => {
  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;';
  const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};