import React from 'react';
import { useCreativeTypes } from '../../hooks/useCreativeTypes';
import { slugify } from '../../services/utils';
import { Button, ButtonStyle } from '../generic/Button';
import * as styles from './OurWorkHero.module.scss';
import DottedLineSvg from '../../assets/dotted-line.svg';

export const OurWorkHero = (): JSX.Element => {
  const creativeTypes: string[] = useCreativeTypes();

  const getAnchors = (): JSX.Element[] => {
    return creativeTypes.map((type: string, index: number): JSX.Element => {
      return <Button key={index} href={`#${slugify(type)}`} style={ButtonStyle.FilledSquareCta}>{type}</Button>;
    });
  };

  return (
    <section data-testid="OurWorkHero" className={styles.OurWorkHero}>
      <DottedLineSvg/>
      <h1>
        Design <span>That Converts</span>
      </h1>
      <p>Our creative team is here to help you experiment with website designs, create custom ads, and craft email campaigns that captivate and convert.<span> We know what it takes to create compelling designs and user experiences that stay true to your brand. Explore how our designs help our clients grow.</span></p>
      <div className={styles.OurWorkHero__anchors}>{getAnchors()}</div>
    </section>
  );
};