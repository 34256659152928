import React, { useEffect, useState } from 'react';
import { Button } from '../../generic/Button';
import ArrowSvg from '../../../assets/arrow.svg';
import * as styles from './Carousel.module.scss';

interface ICarouselProps {
  images: JSX.Element[][];
}

export const Carousel = ({ images }: ICarouselProps): JSX.Element => {
  const [imageIndex, setImageIndex] = useState<number>(0);
  
  useEffect((): void => {
    const modal: HTMLElement | null = document.querySelector(`[data-testid="Modal"]`);
    if (!modal) {
      return;
    }

    modal.scrollTop = 0;
  }, [imageIndex]);

  const getModalButton = (threshold: number, amount: number): JSX.Element | null => {
    const adjustIndex = (): void => setImageIndex(imageIndex + amount);
    const isHidden: boolean = imageIndex === threshold;

    return (
      <Button onClick={adjustIndex} data-hidden={isHidden}>
        <ArrowSvg/>
      </Button>
    );
  };

  return (
    <div data-testid="Carousel" className={styles.Carousel}>
      {getModalButton(0, -1)}
      <div className={styles.Carousel__imageWrapper}>{images[imageIndex]}</div>
      {getModalButton(images.length - 1, 1)}
    </div>
  );
};