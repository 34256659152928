import React from 'react';
import * as styles from './ServiceLineHeader.module.scss';
import { slugify } from '../../../services/utils';

export interface IServiceLineHeaderProps {
  name: string;
  details: string;
}

export const ServiceLineHeader = ({ name, details }: IServiceLineHeaderProps): JSX.Element => {
  return (
    <div data-testid="ServiceLineHeader" id={slugify(name)} className={styles.ServiceLineHeader} data-service-line={name}>
      <h2>
        <span>{name}</span> Designs
      </h2>
      <p>{details}</p>
    </div>
  );
};