import React from 'react';
import * as styles from './ServiceLine.module.scss';
import { IServiceLineHeaderProps, ServiceLineHeader } from './ServiceLineHeader';
import { ICaseStudyProps, CaseStudy } from './CaseStudy';

export interface IServiceLineProps extends IServiceLineHeaderProps {
  entries: ICaseStudyProps[];
}

export const ServiceLine = ({ name, details, entries }: IServiceLineProps): JSX.Element => {
  const getCaseStudies = (): JSX.Element[] => {
    return entries.map((entry: ICaseStudyProps, index: number): JSX.Element => {
      return <CaseStudy key={index} {...entry}/>;
    });
  };

  return (
    <div>
      <section data-testid="ServiceLine" className={styles.ServiceLine} data-service-line={name}>
        <ServiceLineHeader name={name} details={details}/>
        {getCaseStudies()}
      </section>
    </div>
  );
};