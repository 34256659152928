import React from 'react';
import { Layout } from '../generic/Layout';
import { OurWorkHero } from '../our-work/OurWorkHero';
import { Hero } from '../generic/Hero';
import { ServiceLineDisplay } from '../our-work/ServiceLineDisplay';
import { FixedNav } from '../generic/navigation/FixedNav';

export const OurWorkPage = (): JSX.Element => {
  return (
    <main data-testid="OurWork">
      <Layout>
        <FixedNav elementSelector="[data-testid='Hero']"/>
        <Hero>
          <OurWorkHero/>
        </Hero>
        <ServiceLineDisplay/>
      </Layout>
    </main>
  );
};