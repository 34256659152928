import React, { useEffect, useState } from 'react';
import CroLogoSvg from '../../../assets/cro-logo.svg';
import * as styles from './FixedNav.module.scss';
import { Button, ButtonStyle } from '../Button';
import { Hamburger } from './Hamburger';

interface IFixedNavProps {
  elementSelector: string;
}

export const FixedNav = ({ elementSelector }: IFixedNavProps): JSX.Element => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect((): (() => void) | void => {
    const element: HTMLElement | null = document.querySelector(elementSelector);
    if (!element) {
      return;
    }

    const options: IntersectionObserverInit = {
      rootMargin: '-111px',
      threshold: 0
    };

    const observer: IntersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]): void => {
      for (const entry of entries) {
        setIsDisplayed(!entry.isIntersecting);
      }
    }, options);
    observer.observe(element);

    return (): void => observer.disconnect();
  }, []);

  useEffect((): (() => void) | void => {
    const observer: ResizeObserver = new ResizeObserver((): void => {
      setIsActive(false);
    });
    observer.observe(document.body);

    return (): void => observer.disconnect();
  }, []);

  const backToTop = (): void => {
    window.scrollY = 0;
  };

  const toggleIsActive = (): void => {
    setIsActive(!isActive);
  };

  const removeIsActive = (): void => {
    setIsActive(false);
  };

  return (
    <nav data-testid="FixedNav" className={styles.FixedNav} data-displayed={isDisplayed} data-active={isActive}>
      <Button href="https://crometrics.com">
        <CroLogoSvg/>
      </Button>
      <ul>
        <li>
          <Button href="#cro-experimentation" style={ButtonStyle.FilledSquareCta} onClick={removeIsActive} className={styles.FixedNav__button}>CRO Experimentation</Button>
        </li>
        <li>
          <Button href="#performance-marketing" style={ButtonStyle.FilledSquareCta} onClick={removeIsActive} className={styles.FixedNav__button}>Performance Marketing</Button>
        </li>
        <li>
          <Button href="#lifecycle-marketing" style={ButtonStyle.FilledSquareCta} onClick={removeIsActive} className={styles.FixedNav__button}>Lifecycle Marketing</Button>
        </li>
        <li>
          <Button href="#landing-pages" style={ButtonStyle.FilledSquareCta} onClick={removeIsActive} className={styles.FixedNav__button}>Landing Pages</Button>
        </li>
        <li>
          <Button href="#" style={ButtonStyle.NoColorCta} onClick={backToTop}>Back to Top</Button>
        </li>
      </ul>
      <Hamburger isActive={isActive} onToggle={toggleIsActive} mobileAnimate={false}/>
    </nav>
  );
};