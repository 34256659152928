import React from 'react';
import { SEO } from '../components/generic/SEO';
import { OurWorkPage } from '../components/pages/OurWork';

const OurWork = (): JSX.Element => {
  return <OurWorkPage/>;
};
export default OurWork;

export const Head = (): JSX.Element => {
  return <SEO title={'Our Work'} pathname="/our-work"/>;
};